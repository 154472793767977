import React from 'react';

import SocialIllustrationLight from '../../images/social-illustration-light.svg';
import SocialIllustrationDark from '../../images/social-illustration-dark.svg';
import CreatorIllustrationLight from '../../images/creators-illustration-light.svg';
import CreatorIllustrationDark from '../../images/creators-illustration-dark.svg';
import StarcoinIllustrationLight from '../../images/starcoin-illustration-light.svg';
import StarcoinIllustrationDark from '../../images/starcoin-illustration-dark.svg';
import NFTSIllustrationLight from '../../images/nfts-illustration-light.svg';
import NFTSIllustrationDark from '../../images/nfts-illustration-dark.svg';

const Content = () => (
  <>
    <section className="py-12 bg-white dark:bg-gray-900 sm:py-16 lg:py-20">
      <div className="px-6 mx-auto max-w-7xl sm:px-12 lg:px-16 xl:px-20">
        <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-x-24">
          <div>
            <img className="block w-full max-w-sm mx-auto dark:hidden" src={SocialIllustrationLight} alt="Social Media" />
            <img className="hidden w-full max-w-sm mx-auto dark:block" src={SocialIllustrationDark} alt="Social Media" />
          </div>

          <div>
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
              Social shouldn't be this hard
            </h2>
            <p className="mt-6 text-lg font-medium text-gray-500 dark:text-gray-200">
              Creator Monetization tools don't focus on social media features.
            </p>
            <p className="mt-4 text-lg font-medium text-gray-500 dark:text-gray-200">
              Social Media is all about ads, algorithms and exploiting your data, with addictive low quality content
              for your users.
            </p>
            <p className="mt-4 text-lg font-medium text-gray-500 dark:text-gray-200">
              No more juggling multiple apps to get what your content deserves.
            </p>
          </div>
        </div>

        <div className="grid items-center grid-cols-1 mt-12 gap-y-12 lg:grid-cols-2 lg:gap-x-24 sm:mt-16 lg:mt-20">
          <div className="lg:order-2">
            <img className="block w-full max-w-sm mx-auto dark:hidden" src={CreatorIllustrationLight} alt="Creator" />
            <img className="hidden w-full max-w-sm mx-auto dark:block" src={CreatorIllustrationDark} alt="Creator" />
          </div>

          <div className="lg:order-1">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
              Create what's true to you
            </h2>
            <p className="mt-6 text-lg font-medium text-gray-500 dark:text-gray-200">
              Creators are incentivized to be authentic, and are given clear ownership of their creations.
            </p>
            <p className="mt-4 text-lg font-medium text-gray-500 dark:text-gray-200">
              Empower your regular social media content with Subscriptions, Merchandise, NFTS, Live Sessions,
              Workshops, Podcasts
              Campaigns, 1-on-1 interactions, personalized video/text/audio messages and more!
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="py-12 bg-gray-50 dark:bg-gray-800 sm:py-16 lg:py-20">
      <div className="px-6 mx-auto max-w-7xl sm:px-12 lg:px-16 xl:px-20">
        <div className="grid items-center grid-cols-1 gap-y-12 lg:grid-cols-2 lg:gap-x-24">
          <div className="lg:order-2">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
              Introducing Star Coins
            </h2>
            <p className="mt-6 text-lg font-medium text-gray-500 dark:text-gray-200">
              All transactions on <span className="font-bold text-primary-400">STARBEAT</span> happen in Star Coins, a
              powerful, low-friction digital currency
            </p>
            <ul className="mt-8 space-y-5 text-lg font-medium text-gray-500 dark:text-gray-200">
              <li className="flex items-start">
                <div
                  className="inline-flex items-center justify-center flex-shrink-0 mr-3 rounded-full w-7 h-7 bg-secondary-100 text-secondary-500 dark:bg-secondary-600 dark:text-white">
                  <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                Transact & Withdraw from anywhere, anytime in your own currency.
              </li>

              <li className="flex items-start">
                <div
                  className="inline-flex items-center justify-center flex-shrink-0 mr-3 rounded-full w-7 h-7 bg-secondary-100 text-secondary-500 dark:bg-secondary-600 dark:text-white">
                  <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                Simplified Transactions as we navigate crypto for you.
              </li>

              <li className="flex items-start">
                <div
                  className="inline-flex items-center justify-center flex-shrink-0 mr-3 rounded-full w-7 h-7 bg-secondary-100 text-secondary-500 dark:bg-secondary-600 dark:text-white">
                  <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                It's designed to be safe, secure and transparent for all users.
              </li>
            </ul>
          </div>

          <div className="lg:order-1">
            <img className="block w-full max-w-sm mx-auto dark:hidden" src={StarcoinIllustrationLight} alt="Star Coin" />
            <img className="hidden w-full max-w-sm mx-auto dark:block" src={StarcoinIllustrationDark} alt="Star Coin" />
          </div>
        </div>

        <div className="grid items-center grid-cols-1 mt-12 gap-y-12 lg:grid-cols-2 lg:gap-x-24 sm:mt-16 lg:mt-20">
          <div>
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
              More than just NFTs
            </h2>
            <p className="mt-6 text-lg font-medium text-gray-500 dark:text-gray-200">
              From creating wallets to sales, auctions to even reselling on Open Sea, we’ve uncomplicated NFTs for
              everyone on
              STARBEAT
            </p>
            <ul className="mt-8 space-y-5 text-lg font-medium text-gray-500 dark:text-gray-200">
              <li className="flex items-start">
                <div
                  className="inline-flex items-center justify-center flex-shrink-0 mr-3 rounded-full w-7 h-7 bg-secondary-100 text-secondary-500 dark:bg-secondary-600 dark:text-white">
                  <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                Protect your digital rights and authentic content.
              </li>

              <li className="flex items-start">
                <div
                  className="inline-flex items-center justify-center flex-shrink-0 mr-3 rounded-full w-7 h-7 bg-secondary-100 text-secondary-500 dark:bg-secondary-600 dark:text-white">
                  <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                Own all your data and control your privacy.
              </li>

              <li className="flex items-start">
                <div
                  className="inline-flex items-center justify-center flex-shrink-0 mr-3 rounded-full w-7 h-7 bg-secondary-100 text-secondary-500 dark:bg-secondary-600 dark:text-white">
                  <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                Send your fans digital autographs, photos, video messages and more with complete credibility and proof
                of originality.
              </li>
            </ul>
          </div>

          <div>
            <img className="block w-full max-w-sm mx-auto dark:hidden" src={NFTSIllustrationLight} alt="NFT" />
            <img className="hidden w-full max-w-sm mx-auto dark:block" src={NFTSIllustrationDark} alt="NFT" />
          </div>
        </div>
      </div>
    </section>
  </>
);

export default Content;
