import React from 'react';

const TransactionRebate = () => (
  <section className="py-12 bg-white dark:bg-gray-900 sm:py-16 xl:py-28 lg:py-20">
    <div className="px-6 mx-auto max-w-7xl sm:px-12 lg:px-16 xl:px-20">
      <div className="max-w-3xl mx-auto text-center">
        <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
          Early Access Creators get a rebate on the Transaction Fees from us</h2>
        <p className="mt-3 text-lg font-medium text-gray-500 dark:text-gray-200 sm:mt-4">
          As compared to slashing over 50% of a creator's revenue for advertisers and publishers like other social media, we charge a token to maintain our services, data storage and actively work towards developing a more creator-driven social media
        </p>
      </div>
    </div>
  </section>
);

export default TransactionRebate;
