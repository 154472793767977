import React from "react";

import CreatorsHeroLight from '../../images/creators-hero-light.png';
import CreatorsHeroDark from '../../images/creators-hero-dark.png';

const Hero = () => (
  <>
    <section className="bg-white dark:bg-gray-900">
      <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative overflow-hidden rounded-3xl bg-secondary-100 dark:bg-secondary-900">
          <div className="relative px-6 py-12 sm:px-12 sm:pt-16 lg:px-16 lg:pt-20 xl:px-20">
            <div className="max-w-3xl mx-auto text-center">
              <p className="text-sm font-bold tracking-widest uppercase text-secondary-500 dark:text-secondary-400">
                we make it easy
              </p>
              <h1
                className="mt-6 text-3xl font-bold text-gray-900 dark:text-white sm:text-4xl lg:text-5xl sm:tracking-tight">
                Freedom to Monetize
              </h1>
              <p className="mt-6 text-lg font-medium text-gray-500 dark:text-gray-200 lg:text-xl">
                Because you're expected to grow an audience, manage a community, earn from what you love doing, keep a
                track of
                algorithms and trends; all the while staying on top of your creative game
              </p>
            </div>

            <div className="max-w-4xl mx-auto mt-12 sm:mt-16 lg:mt-20">
              <img className="block w-full mx-auto dark:hidden" src={CreatorsHeroLight} alt="Profile and Dashboard for Creators" />
              <img className="hidden w-full mx-auto dark:block" src={CreatorsHeroDark} alt="Profile and Dashboard for Creators" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="hidden pt-12 bg-white dark:bg-gray-900 sm:pt-16">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <p className="text-sm font-bold tracking-widest uppercase text-secondary-500 dark:text-secondary-400">
            we make it easy
          </p>
          <h1 className="mt-6 text-3xl font-bold text-gray-900 dark:text-white sm:text-4xl lg:text-5xl sm:tracking-tight">
            Freedom to Monetize
          </h1>
          <p className="mt-6 text-lg font-medium text-gray-500 dark:text-gray-200 lg:text-xl">
            Because you're expected to grow an audience, manage a community, earn from what you love doing, keep a
            track of
            algorithms and trends; all the while staying on top of your creative game
          </p>
        </div>
      </div>

      <div className="pb-12 mt-10 bg-white sm:pb-16">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-gray-50"></div>
          <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
              <img className="w-full" src={CreatorsHeroLight} alt="Profile and Dashboard for Creators" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Hero;
