import React from 'react';

const FAQs = () => (
  <section id="faq" className="py-12 bg-gray-50 dark:bg-gray-800 sm:py-16 xl:py-28 lg:py-20">
    <div className="px-6 mx-auto max-w-7xl sm:px-12 lg:px-16 xl:px-20">
      <div className="text-center">
        <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
          Frequently Asked Questions
        </h2>
        <p className="mt-5 text-base font-medium text-gray-500 dark:text-gray-400">
          Everything you need to know about the product
        </p>
      </div>

      <div className="mt-16">
        <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
          <div>
            <div className="text-lg font-semibold leading-6 text-gray-900 dark:text-white">
              So is this Free?
            </div>
            <div className="mt-3 text-base font-medium text-gray-500 dark:text-gray-400">
              Yes, it's completely free to use. Creators can create content and decide whether they want to keep
              it
              free for
              followers or exclusive for subscribers who are willing to pay.
            </div>
          </div>

          <div>
            <div className="text-lg font-semibold leading-6 text-gray-900 dark:text-white">
              How do NFTs work?
            </div>
            <div className="mt-3 text-base font-medium text-gray-500 dark:text-gray-400">
              When it's time to mint, we'll help you create a crypto wallet to store, sell and list your NFTs for
              free, right from the
              app.<br /><br />We have an embedded MetaMask wallet which lets you import your existing wallet as well
            </div>
          </div>

          <div>
            <div className="text-lg font-semibold leading-6 text-gray-900 dark:text-white">
              How do Live Sessions, Stardubs & Shoutouts work?
            </div>
            <div className="mt-3 text-base font-medium text-gray-500 dark:text-gray-400">
              No third party integration required (e.g. Zoom).Schedule a live session and start directly from the
              app. It works just
              like any other social media!<br /><br />
              Stardubs let your fans remix authentic content, Shoutouts are personalized video messages creators
              can
              send to fans as
              part of a perk
            </div>
          </div>

          <div>
            <div className="text-lg font-semibold leading-6 text-gray-900 dark:text-white">
              What is your withdrawal policy?
            </div>
            <div className="mt-3 text-base font-medium text-gray-500 dark:text-gray-400">
              In Early Access, creators can start withdrawing as soon as they hit 200 coins or 25 subscribers
            </div>
          </div>

          <div>
            <div className="text-lg font-semibold leading-6 text-gray-900 dark:text-white">
              Can I change my subscription midway?
            </div>
            <div className="mt-3 text-base font-medium text-gray-500 dark:text-gray-400">
              Creators can edit plans for new subscribers coming in.<br /><br />
              Fans can always upgrade to a higher plan. Their plan benefits will unlock instantly & upgraded plan
              will begin after
              their current plan ends. Win-Win.
            </div>
          </div>

          <div>
            <div className="text-lg font-semibold leading-6 text-gray-900 dark:text-white">
              How do I change/remove account details?
            </div>
            <div className="mt-3 text-base font-medium text-gray-500 dark:text-gray-400">
              You can change account details from the app itself.<br /><br />
              You can also pause/suspend your account to keep your data or request deletion for a complete wipe
            </div>
          </div>
        </div>
      </div>

      <div
        className="mt-16 overflow-hidden text-center bg-gray-100 border border-gray-200 lg:text-left dark:bg-gray-800 dark:border-gray-700 rounded-xl">
        <div className="px-4 py-5 sm:px-8 sm:py-6">
          <div className="justify-between lg:space-x-8 lg:items-center lg:flex">
            <div className="lg:flex-1">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Still have questions?
              </h3>
              <p className="mt-2 text-base font-medium text-gray-500 dark:text-gray-400">
                Can't find the answer you're looking for? We're here to help!
              </p>
            </div>

            <div className="mt-6 lg:mt-0">
              <a href="mailto:hello@starbeat.com" title="Get In Touch"
                className="inline-flex items-center px-6 py-3 text-base font-semibold text-white transition-all duration-200 border border-transparent rounded-lg shadow-lg bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 dark:bg-primary-400 dark:hover-bg-primary-500 dark:focus:ring-primary-400">
                Get In Touch
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default FAQs;
