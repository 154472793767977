import * as React from "react";

import SuperHeader from "../components/superHeader";
import Header from './../components/header';
import InnerLayout from '../components/innerLayout';
import Hero from "../modules/creators/hero";
import Content from '../modules/creators/content';
import EarlyAccess from "../components/earlyAccess";
import TransactionRebate from "../modules/creators/transactionRebate";
import FAQs from '../modules/creators/faqs';
import Footer from './../components/footer';
import useComposeUrl from "../hooks/useComposeUrl";

const Creators = ({ location }) => {
  const currentPageUrl = useComposeUrl(location.pathname);

  return (
    <>
      <SuperHeader
        title="Creators / Starbeat"
        metaTitle="Empowering Creators"
        metaDescription="All the good of social media meets the freedom of Creator Monetization"
        currentUrl={currentPageUrl}
      />
      <Header />
      <InnerLayout>
        <Hero />
        <Content />
        <TransactionRebate />
        <FAQs />
        <EarlyAccess />
      </InnerLayout>
      <Footer />
    </>
  );
}

export default Creators;
